import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from './root';
import { Geo, GeoListDocument } from '../gql/generated';
import Cookies from 'js-cookie';
import { graphQLClient } from '../utils/graphql-api';
import { getDomain } from '../utils/domain';
import { IncomingHttpHeaders } from 'http2';
import { gql } from '@apollo/client';

const defaultGeo = {
  name: 'Москва',
  slug: 'moskva',
  r: 'Москвы',
  g: 'в Москве',
  coordinates: '37.605966 55.612329',
  address: ['Columbus', 'Кировоградская улица, 13А', '117519', '(495) 114-44-44'],
};

/**
 * Гео
 */
export default class GeoStore {
  root: RootStore;
  geo = {
    name: defaultGeo.name,
    slug: defaultGeo.slug,
  };
  citiesBySlug: Map<string, Geo> = new Map();
  conflict = false;
  without = false;

  list: Geo[] = [];

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
    this.setList([defaultGeo]);
  }

  hydrate = (data: Geo, host: string, headers: IncomingHttpHeaders) => {
    if (data) {
      this.setList([data]);
    }
    this.setInitialLocation(host, headers);
  };

  setLocation = (data) => {
    // this.geo = {
    //   name: data.name,
    //   slug: data.slug,
    // };
    // this.conflict = false;
    Cookies.set('geo', data.slug, { path: '/', domain: getDomain(), expires: 7 });

    const { domain } = this.root.project;
    const link =
      window.location.protocol +
      '//' +
      data.slug +
      '.' +
      domain +
      window.location.pathname +
      window.location.search;

    window.location.replace(link);
  };

  acceptLocation = () => {
    Cookies.set('geo', this.geo.slug, { path: '/', domain: getDomain(), expires: 7 });
    this.conflict = false;
  };

  setInitialLocation = async (host: string, headers: IncomingHttpHeaders) => {
    const cityFromURL = getCityFromURL(this.root.project.domain, host);

    if (cityFromURL) {
      this.geo = {
        name: this.citiesBySlug.get(cityFromURL)?.name ?? null,
        slug: cityFromURL,
      };
    }

    if (!process.browser) return;

    const geoFromCookie = Cookies.get('geo');

    if (geoFromCookie === 'null' && this.geo.slug === null) {
      this.without = true;
      return;
    }

    if (!this.without && !geoFromCookie === undefined && geoFromCookie !== this.slug) {
      this.conflict = true;
    }

    if (this.slug && geoFromCookie !== this.slug) {
      this.conflict = true;
    }
  };

  setList = (data: Geo[]) => {
    this.list = data;
    this.citiesBySlug = new Map(data.map((geo) => [geo.slug, geo]));
  };

  setWithout = (state: boolean) => {
    this.without = state;
    Cookies.set('geo', 'null', { path: '/', domain: getDomain(), expires: 7 });
  };

  fetchList = async () => {
    if (this.list.length < 2) {
      const geoList = await graphQLClient().query({ query: GeoListDocument });

      runInAction(() => {
        this.setList(geoList.data.geoList);
      });
    }

    return this.list;
  };

  get name() {
    return this.geo.name;
  }

  get slug() {
    return this.geo.slug;
  }

  get toponim() {
    // return this.slug ? this.citiesBySlug.get(this.geo.slug)?.g : null;
    return 'в Новороссийске';
  }

  get current() {
    return this.slug ? this.citiesBySlug.get(this.geo.slug) : null;
  }

  get cityName() {
    // return this.slug ? this.citiesBySlug.get(this.geo.slug)?.g : 'в России';
    return 'в Новороссийске';
  }

  get cityNameR() {
    // return this.slug ? `из ${this.citiesBySlug.get(this.geo.slug)?.r}` : 'из России';
    return 'Новороссийска';
  }
}

const getCityFromURL = (baseDomain: string, host: string): string | null => {
  const splitURL = host.split(`.${baseDomain}`);
  const hasCityInURL = splitURL.length === 2;

  if (!hasCityInURL) return null;

  return splitURL[0];
};

const fetchGeoList = async (): Promise<Geo[]> => {
  const { data } = await graphQLClient().query({
    query: GeoListDocument,
  });

  return data.geoList;
};
